body {
  margin: 0;
}
/* TODO: Once className change in wayfarer, need to update accordingly */
.wfnm91, .dcdca2, .ko25fk,
.afpxxu,.cknfjrc {
  cursor: pointer;
}
.toolkit-card:hover, .content-portal-card:hover, .clients-card:hover {
  box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.12);
  border: 1px solid #426DA9;
}
.clients-card:hover .client-tiles-heading-text, .toolkit-card:hover .toolkit-tiles-title {
  color: #1D4F91;
}
/* TODO: Once className change in wayfarer, need to update accordingly */
.q246ja {
  padding: 5px 8px 5px 8px;
}
.content-portal-card a:-webkit-any-link, .clients-card a:-webkit-any-link {
  text-decoration: none;
}
.clients-card .wyfr-container-base___children-trim {
  justify-content: center;
  overflow: visible;
}
.clients-card {
  background-color: #F2F8FF;
  border-width: 1px;
  border-style: solid;
  border-color: #426DA9;
}
.clients-card:hover {
  background-color: #D6E3F4;
  border-color: #1D4F91;
}
.client-tiles-heading-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.todo-video iframe, .client-video iframe {
  width: 100%;
}
.search-form .wyfr-field___controls {
  padding-top: 0;
}
.search-form .wyfr-input___control {
  height: 42px;
}
/* Custom tooltip CSS - start */
.tooltip{
  position: relative;
}
.tooltip .wyfr-token___x {
  cursor: pointer;
}
.tooltiptext{
  width: 250px;
  background-color: #5b5d5f;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  font-size: 10px;
  left: 50%;
  margin-left: -120px;
  visibility: hidden;
}

.tooltiptext::after{
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  border-style: solid;
  border-color: #5b5d5f transparent transparent transparent;
  margin-left: -5px;
  border-width: 5px;
}

.tooltip:hover .tooltiptext{
  visibility: visible;
  white-space: normal;
}
/* Custom tooltip CSS - end */

/* Custom tooltip CSS - end */

.feature-card:hover {
  background-color: #D6E3F4;
  transition: all 0.2s ease-out;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.12);
  top: -4px;
  background-color: white;
  cursor: pointer;
}

/* .searchInputContainer{
  width: 100%;
  min-height: 4em;
  display: flex;
  align-items: center;
  position: relative;
  padding: 2px 15px; 
} */

.SearchContent {
  overflow-y: auto;
  /* background-color: #ffffff;
    border-left-width: 8px;
    border-left-style: solid;
    border-left-color: #F2F8FF */
  border-radius: 8px;
  border: 1px;
  border-style: solid;
  border-color: #D6D6D6;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  vertical-align: middle;
  height: 140px;
  margin-bottom: 5;
  padding-top: 1;
  transform: translateY();
  transition: var(--speed) ease-out;
}



.found-ideas::-webkit-scrollbar-track {
  background-color: #1D4F91;
}

.dataItem {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dataItem:hover {
  background-color: #883B92;
  vertical-align: middle;
  margin-right: 2px;
  transition: ease-in-out;
  transition-duration: 300ms;
}

.dataItem:hover .dataItemtext {
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  flex-direction: row;
  display: inline;
  transition: ease-in-out;
  transition-duration: 200ms;
}
.text-list-item:hover {
  background-color: #a1a1a1;
  cursor: pointer;
}
.WarningMessage {
  color: #a1a1a1;
  font-size: 14px;
  display: flex;
  align-self: center;
  justify-self: center;
}
.new-line {
  white-space: pre-line;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}