.todo-dropdown {
    margin-top: auto;
}

/* TODO: Once className change in wayfarer, need to update accordingly */
.todo-dropdown .s11bpi {
    width: 250px;
}

.todo-download {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 16px;
    margin: 20px 0;
    border: 1px solid #426DA9;
    border-radius: 8px;
    gap: 16px;
    cursor: pointer;
}

.todo-upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dotted #8DABD4;
    margin: 20px 0;
    border-radius: 8px;
    padding: 24px;
    gap: 5px;
    cursor: pointer;
    background-color: #F6F6F6;
}

.todo-upload.drag-active {
    background-color: #ffffff;
    border-width: 3px;
    opacity: 0.5;
}
.choose-file.drag-active {
    background-color: #F6F6F6;
    opacity: 0.5;
}
.todo-search .q5ebow {
    background-color: #EAEAEA;
    padding: "7px 8px";
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.todo-search .eiqp620 {
    margin: 0 5px !important;
}
.filteredToken .wyfr-token___text {
    white-space: nowrap;
    max-width: 185px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.filteredToken .wyfr-text___container {
    cursor: pointer;
}
.todo-client-details .wyfr-field___root-common {
    width: 100%;
}
.wyfr-button___content-common {
    align-items: center;
}
.todo-client-details .todo-dropdown .wyfr-field___root-common .wyfr-container-base___corner-radius-4 {
    width: 100%;
}
.todo-modal-header .ci2p6a {
    text-align: left;
}
.sts-fields .gk6n9i {
    font-size: 4px;
}
.sts-fields .wyfr-field___root-common {
    margin-bottom: 20px;
}
.sts-fields .strike-color, .sso-fields .strike-color , .internal-input .strike-color{
    --stroke-color: red;
}
.no-data-alert .ct5y4mq {
    line-height: 35px;
}
.delete-cancel-btn .apo63w {
    padding-left: 0;
}
.choosed-item-btn .MuiSvgIcon-root {
    width: 18px;
    cursor: pointer;
}
.choosed-item-btn, .choosed-item-btn .wyfr-disc___medium {
    --wyfr-disc-size: 20px;
}